<template>
  <div>
    <v-expansion-panels accordion flat class="accordion">
      <v-expansion-panel
        v-for="characteristic in categoryCharacteristic"
        :key="characteristic.index"
        class="secondary mb-4"
      >
        <v-expansion-panel-header class="font-weight-bold accordion--title">
          {{ characteristic.title }}
          <template v-slot:actions>
            <v-icon color="white" small>$plus</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="white py-5">
          <v-dialog
            v-model="dialog"
            :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
          >
            <v-btn icon @click="dialog = false" style="float: right;">
              <v-icon>$close</v-icon>
            </v-btn>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                :src="characteristic.content"
              ></v-img>
            </template>
            <v-img :src="characteristic.content"></v-img>
          </v-dialog>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-img cover :src="categoryFooterImg" height="200px"></v-img>
  </div>
</template>
<style lang="scss">
.accordion {
  &--title {
    color: var(--v-secondary-darken4);
  }
}
.v-expansion-panel-header--active {
  background-color: var(--v-grey-lighten2);
}
</style>
<script>
export default {
  name: "ProductAccordion",
  props: {},
  components: {},
  data() {
    return {
      dialog: false,
      categoryCharacteristic: [
        {
          title: "Identification Drive Shafts and Crosses",
          content: "/img_layout/dr_plans_1.png"
        },
        {
          title: "identification Connecting profiles",
          content: "/img_layout/dr_plans_2.png"
        },
        {
          title: "Identification Profiles",
          content: "/img_layout/dr_plans_3.png"
        },
        {
          title: "Identification Form of Attachment",
          content: "/img_layout/dr_plans_4a.jpeg"
        }
      ],
      categoryFooterImg: "/img_layout/dr_plans_4.jpeg"
    };
  },
  computed: {},
  methods: {},
  created() {}
};
</script>
