var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products category-med"},[_c('Breadcrumb',{attrs:{"items":_vm.category.navigationTypeId != 0 ? _vm.breadcrumbs : _vm.breadcrumb}}),_c('category-block',{staticClass:"category-block category-block-1",attrs:{"target":_vm.category,"position":"position1"}}),_c('v-container',{staticClass:"py-0"},[_c('CategoryTitle',{attrs:{"category":_vm.category,"count":_vm.count,"selectedCategory":_vm.selectedCategory}}),(_vm.category.categoryId == 148000615)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticStyle:{"color":"#565657 !important"}},[_vm._v(" Radial pin clutches belong to the group of torque-pulsating clutches. Spring-loaded cams, acting as locking elements, radially engage special grooves in the housing. If the clutch torque is exceeded, the locking elements are pressed against the springs as a result of vectorial force resolution, until the positive connection to the housing is released. During the slipping phase, the cams repeatedly engage the grooves in the housing, thereby generating pulsating torques. The mechanical energy to be transmitted is primarily converted into elastic spring deformation energy. The clutch torque is dependent on the clutch design, the type of spring and the number of springs. Axially acting balls are only rarely used as locking elements in practice, owing to their axially acting, pulsating forces. ")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{attrs:{"src":"/img_layout/clutches_1.jpg"}})],1)],1)],1):_vm._e(),(_vm.category.children)?_c('div',{staticClass:"subcategories"},[_c('v-chip-group',{attrs:{"show-arrows":""}},_vm._l((_vm.category.children),function(subCategory){return _c('v-chip',{key:subCategory.categoryId,staticClass:"mr-2 mb-2 rounded-lg primary--text primary lighten-2",attrs:{"label":"","link":"","exact-active-class":"secondary","to":_vm.category.navigationTypeId == 0
              ? { name: 'Category', params: { pathMatch: subCategory.slug } }
              : {
                  name: 'Category',
                  params: {
                    pathMatch: _vm.category.slug
                  },
                  query: { categoryId: subCategory.categoryId }
                }}},[_vm._v(" "+_vm._s(subCategory.name)+" ")])}),1)],1):_vm._e()],1),(_vm.category.categoryId == 148000613)?_c('v-container',[_c('ProductAccordion')],1):_vm._e(),_c('v-container',[_c('ProductListGrid',{key:_vm.categoryId || _vm.category.categoryId,attrs:{"parentCategoryId":_vm.category.categoryId,"categoryId":_vm.categoryId,"hideFilterString":"Categorie,Main Term","itemListName":"Category Med"},on:{"productsCount":_vm.updateCount}})],1),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"position":"position2"}}),_c('category-block',{staticClass:"category-block category-block-3",attrs:{"target":_vm.category,"position":"position3"}}),_c('v-container',[_c('ebsn-meta',{attrs:{"target":_vm.category,"path":"category_info.FOOTER_DESCRIPTION","tag":"div"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }